<template id="ReportViewer">
  <div class="report-viewer">
    <div class="header">
      <div class="title-row">
        <h2>유류관리 일일정산 보고서</h2>
        <b-button size="sm" variant="info" @click="showDownloadModal"><b-icon-file-earmark-excel-fill /> 다운로드</b-button>
      </div>
      <div class="header-details">
        <div class="header-item">
          <strong>부대명</strong>
          <span>{{ reportData.header.부대명 }}</span>
        </div>
        <div class="header-item">
          <strong>정산기준일</strong>
          <span>{{ reportData.header.정산기준일 }}</span>
        </div>
        <div class="header-item">
          <strong>담당자</strong>
          <span>{{ user.name }} {{ user.position }}</span>
        </div>
        <div class="header-item">
          <strong>기준단위</strong>
          <span>{{ reportData.header.기준단위 }}</span>
        </div>
      </div>
    </div>

    <h4>유종별 현황</h4>
    <table class="custom-table" ref="oilTable">
      <thead>
        <tr>
          <th rowspan="2">유종</th>
          <th colspan="4">측정재고</th>
          <th colspan="4">65°F 환산재고</th>
        </tr>
        <tr>
          <th>시작유량</th>
          <th>금일입고량</th>
          <th>금일출고량</th>
          <th>현재고량</th>
          <th>시작유량</th>
          <th>금일입고량</th>
          <th>금일출고량</th>
          <th>현재고량</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in reportData.유종별현황" :key="item.유종">
          <td>{{ item.유종 }}</td>
          <td>{{ item.시작유량 }}</td>
          <td>{{ item.금일입고량 }}</td>
          <td>{{ item.금일출고량 }}</td>
          <td>{{ item.현재고량 }}</td>
          <td>{{ item.환산시작유량 }}</td>
          <td>{{ item.환산금일입고량 }}</td>
          <td>{{ item.환산금일출고량 }}</td>
          <td>{{ item.환산현재고량 }}</td>
        </tr>
      </tbody>
    </table>

    <h4>탱크별 현황</h4>
    <table class="custom-table" ref="tankTable">
      <thead>
        <tr>
          <th rowspan="2">탱크명</th>
          <th rowspan="2">유종</th>
          <th colspan="3">현재센서측정치</th>
          <th colspan="4">측정재고</th>
          <th colspan="4">65°F 환산재고</th>
        </tr>
        <tr>
          <th>오일높이</th>
          <th>수분높이</th>
          <th>평균온도</th>
          <th>시작유량</th>
          <th>금일입고량</th>
          <th>금일출고량</th>
          <th>현재고량</th>
          <th>시작유량</th>
          <th>금일입고량</th>
          <th>금일출고량</th>
          <th>현재고량</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in reportData.탱크별현황" :key="item.탱크명">
          <td>{{ item.탱크명 }}</td>
          <td>{{ item.유종 }}</td>
          <td>{{ item.오일높이 }}</td>
          <td>{{ item.수분높이 }}</td>
          <td>{{ item.평균온도 }}</td>
          <td>{{ item.시작유량 }}</td>
          <td>{{ item.금일입고량 }}</td>
          <td>{{ item.금일출고량 }}</td>
          <td>{{ item.현재고량 }}</td>
          <td>{{ item.환산시작유량 }}</td>
          <td>{{ item.환산금일입고량 }}</td>
          <td>{{ item.환산금일출고량 }}</td>
          <td>{{ item.환산현재고량 }}</td>
        </tr>
      </tbody>
    </table>

    <b-modal id="downloadModal" title="다운로드 사유 입력" @ok="exportToExcel">
      <b-form-group label="다운로드 사유" label-for="downloadReason">
        <b-form-input
          id="downloadReason"
          v-model="downloadReason"
          placeholder="5자이상 입력"
          required
        ></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import * as XLSX from 'xlsx';

export default {
  props: ['reportData'],
  data() {
    return {
      user: {
        name: '',
        position: ''
      },
      downloadReason: ''
    };
  },
  mounted() {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      this.user.name = storedUser.name;
      this.user.position = storedUser.position;
    }
  },
  methods: {
    showDownloadModal() {
      this.$bvModal.show('downloadModal');
    },
    exportToExcel() {
      if (this.downloadReason.length < 5) {
        this.$bvToast.toast('다운로드 사유는 5자 이상 입력해야 합니다.', {
          title: '사유 없음',
          variant: 'warning',
          solid: true
        });
        return;
      }

      const oilTable = this.$refs.oilTable;
      const tankTable = this.$refs.tankTable;
      const wb = XLSX.utils.book_new();
      const headerData = [
        [], // Blank row
        ['유류관리 일일정산 보고서'], // Adding title row
        [], // Blank row
        ['부대명', this.reportData.header.부대명],
        ['정산기준일', this.reportData.header.정산기준일],
        ['담당자', `${this.user.name} ${this.user.position}`],
        ['기준단위', this.reportData.header.기준단위],
        // ['다운로드 사유', this.downloadReason],
        [], // Blank row
        ['유종별 현황'], // Adding section title
      ];

      const ws = XLSX.utils.aoa_to_sheet(headerData);

      // Add oil table data
      const oilTableData = XLSX.utils.table_to_sheet(oilTable);
      XLSX.utils.sheet_add_aoa(ws, XLSX.utils.sheet_to_json(oilTableData, { header: 1 }), { origin: -1 });

      // Add a blank row after oil table
      XLSX.utils.sheet_add_aoa(ws, [['']], { origin: -1 });

      // Add tank table title and data
      XLSX.utils.sheet_add_aoa(ws, [['탱크별 현황']], { origin: -1 });
      const tankTableData = XLSX.utils.table_to_sheet(tankTable);
      XLSX.utils.sheet_add_aoa(ws, XLSX.utils.sheet_to_json(tankTableData, { header: 1 }), { origin: -1 });

      XLSX.utils.book_append_sheet(wb, ws, "Report");

      // Save to file
      XLSX.writeFile(wb, `유류관리 일일정산 보고서_${new Date().toISOString().slice(0, 10)}.xlsx`);
      
      this.downloadReason = ''; // Clear the reason after download
      this.$bvModal.hide('downloadModal');
    }
  }
}
</script>

<style scoped>
.report-viewer {
  white-space: pre-wrap;
  background: #2f2f2f;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
}
.header {
  text-align: center;
  margin-bottom: 20px;
}
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h2 {
  margin-bottom: 20px;
}
.header-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid #000;
  padding: 10px;
}
.header-item {
  flex: 1 1 45%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.header-item strong {
  flex-basis: 30%;
}
.header-item span {
  flex-basis: 65%;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.custom-table th, .custom-table td {
  border: 1px solid #565656;
  padding: 8px;
  text-align: center;
  font-size: 0.85em; /* Reduced font size */
}
.custom-table th {
  background-color: #3b3b3b;
  color: #fff;
  font-size: 0.9em; /* Reduced font size */
}
.custom-table td {
  background-color: #2f2f2f;
  color: #fff;
}
</style>
