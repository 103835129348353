<template>
  <div class="wrapper">
    <div class="header">
      <div class="title-row">
        <h2>급유차 정보 조회</h2>
        <BButton size="sm" variant="info" @click="()=>{this.downloadModalShow = true}"><BIconFileSpreadsheetFill/></BButton>
      </div>
    </div>

    <b-container fluid>
      <b-row class="mb-3">
        <b-col md="2">
          <BFormSelect size="sm" style="background-color:#2f303a"
                       v-model="selectedTank"
                       :options="tankOpts"/>
        </b-col>
        <b-col md="2">
          <b-form-select size="sm" v-model="selectedFuncCode" :options="funcCodeOpts"></b-form-select>
        </b-col>
        <b-col md="4">
          <b-input-group-append>
            <b-form-input v-model="fromDate" type="date" size="sm" @input="setFromDate" />
            <b-form-input v-model="toDate" type="date" size="sm" @input="setToDate"  class="ml-2" />
            <b-button size="sm" variant="primary" @click="search" class="ml-5">
              <b-icon-search /> 검색
            </b-button>
          </b-input-group-append>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="rows.length">
          <vue-excel-editor
            v-model="rows"
            ref="excelGrid"
            width="100%"
            class="mb-1"
            :page="userPerPage"
            :readonly-style="{backgroundColor:'#4F5040'}"
            filter-row>
            <vue-excel-column field="dt" type="string" width="160px" label="일시" :to-text="toLocalTime" readonly/>
            <vue-excel-column field="tid" type="string" width="80px" label="탱크 번호" readonly />
            <vue-excel-column field="mid" type="string" width="80px" label="급유차 번호" readonly />
            <vue-excel-column field="fcd" type="map" width="80px" label="상태" :options="FuncCodeMap"  readonly />
            <vue-excel-column field="ovm" type="number" width="100px" label="재고량 (liter)" readonly />
<!--            <vue-excel-column field="ofm" type="number" width="100px" label="시작재고 (liter)" readonly />-->
            <vue-excel-column field="tm" type="number" width="80px" label="온도 (°C)" readonly />
<!--            <vue-excel-column field="evts" type="string" width="120px" label="이벤트" readonly />-->
            <vue-excel-column field="location" type="string" width="250px" label="위치" :to-text="v=>{return v?v.coordinates?.toString():''}" readonly />
<!--            <vue-excel-column field="map" type="string" width="150px" label="맵 좌표" readonly />-->
            <vue-excel-column field="distKmSum" type="number" width="150px" label="총 운행 거리 (km)" readonly />
            <vue-excel-column field="opMin" type="number" width="150px" label="운행 시간 (분)" readonly />
            <vue-excel-column field="opMinSum" type="number" width="150px" label="총 운행 시간" :to-text="v=>{return v?toHumanTime(v):''}" readonly />
          </vue-excel-editor>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            size="sm"
            v-model="userCurrPage"
            :total-rows="userRows"
            :per-page="userPerPage"
            aria-controls="userTable"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>

      <CModal ref="download-modal"
              color="warning"
              title="다운로드 사유 입력"
              :show.sync="downloadModalShow">
        <BInputGroup>
          <BFormInput v-model="downloadReason" debounce="6000" placeholder="5자이상 입력"></BFormInput>
          <BInputGroupAppend>
            <BButton variant="outline-danger" block @click="exportExcel"><BIconDownload/> 다운로드</BButton>
          </BInputGroupAppend>
        </BInputGroup>
      </CModal>
  </div>
</template>

<script>
// import axios from 'axios';
import moment from 'moment';
import {
  apiCall,
  accessLogging,
  iosDtToLocal,
} from '@/common/utils';
import qs from 'querystring';
import {FuncCodeMap} from "@/common/constants";

export default {
  computed: {

  },
  data() {
    return {
      FuncCodeMap,
      selectedTank: '',
      selectedFuncCode: '',
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      tankOpts: [],
      funcCodeOpts: [
        // { value: '00', text: '저장소-ATG' },
        // { value: '01', text: '난방유-ATG' },
        { value: '', text: '전체' },
        { value: '02', text: '급유' },
        { value: '03', text: '배유' },
        { value: '04', text: '인증' },
        { value: '05', text: 'Off' },
        { value: '06', text: '대기' },
        { value: '07', text: '시험' },
      ],
      rows: [],

      userPerPage: 10,
      userCurrPage: 1,
      userRows: 0,
      downloadReason: '',
      downloadModalShow: false,
    };
  },
  mounted() {
    this.fetchTankOptions();
  },
  methods: {
    changeTid(event) {

    // this.selectedTank = event.target.value;
    },
    async fetchTankOptions() {
      try {
        const rs = await apiCall('get','/api/tank/lorry/init');
        // this.tankOpts = response.data.result.opts;
        if( rs.code===200) {
          this.tankOpts = rs.result.opts;
          console.log( "fetchTankOptions --------->", this.tankOpts )
        }
      } catch (error) {
        console.error('Error fetching tank options:', error);
      }
    },
    setFromDate() {
      this.toDate = moment(this.fromDate).add(7, 'days').format('YYYY-MM-DD');
    },
    setToDate() {
      if (this.toDate < this.fromDate) this.fromDate = this.toDate;
    },
    async search() {
      try {
        const params = {
          tid: this.selectedTank,
          fcd: this.selectedFuncCode,
          fromDate: this.fromDate,
          toDate: this.toDate,
        };

        const qry = qs.stringify( params );
        const response = await apiCall('get', `/api/tank/report?${qry}`);
        this.rows = response.result;
        this.userRows = this.rows.length;
        await this.toastResult(response);
      } catch (error) {
        console.error('Error fetching report data:', error);
      }
    },
    userRowSelected(items) {
      console.log('Selected items:', items);
    },
    showDownloadModal() {
      this.$bvModal.show('downloadModal');
    },
    async exportExcel() {
      console.log('------------exportExcel----------')
      try {
        const numRecs = this.$refs['excelGrid'].getSelectedRecords().length;

        if (!numRecs) {
          this.downloadModalShow = false;
          return await this.alertWarn("선택한 레코드만 파일로 생성됩니다.", '선택 레코드 없음');
        }

        if (!this.downloadReason || this.downloadReason.length < 5) {
          return await this.alertWarn("다운로드 사유는 5자 이상 입력해야 합니다.", '사유 없음');
        }

        const { currentRoute } = this.$router;
        const { fields } = this.$refs["excelGrid"];
        const format = 'xlsx';
        const exportSelectedOnly = true;

        const filename = currentRoute.path.split('/').pop() + '-' + moment().format('YYYY-MM-DD');
        const labels = fields.map(i => { return i.label });
        const rs = await accessLogging(
          'download',
          currentRoute,
          labels,
          this.downloadReason,
          `레코드수: ${numRecs}, 파일명: ${filename}.${format}`
        );

        if (rs === true) {
          this.$refs['excelGrid'].getSelectedRecords().map(i => {
            i.dt = iosDtToLocal(i.dt);
          });

          this.$refs["excelGrid"].exportTable(format, exportSelectedOnly, filename);
          this.downloadReason = '';
          return true;
        } else {
          await this.alertDanger("ERROR: " + rs.message);
          return false;
        }

      } catch (err) {
        console.log('exportExcel error', err);
      } finally {
        this.downloadModalShow = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  background: #2f2f2f;
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  font-family: Arial, sans-serif;
}
.header {
  text-align: center;
  margin-bottom: 20px;
}
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header h2 {
  margin-bottom: 20px;
}
.ml-2 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 50px;
}
</style>
